import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import './ServantSection.css';

function ServantSection() {
  return (
    <section className="servant-section">
     
      
      <div className="carousel-container">
        <Carousel
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={2000}
        >
          <div>
            <img 
              src="https://radhadamodar-solapur.s3.amazonaws.com/21_09_2024_55" 
              alt="Sri Sri Radha Damodar 1"
            />
          </div>
          <div>
            <img 
              src="https://radhadamodar-solapur.s3.amazonaws.com/06_09_2024_34" 
              alt="Sri Sri Radha Damodar 2"
            />
          </div>
          <div>
            <img 
              src="https://radhadamodar-solapur.s3.amazonaws.com/11_09_2024_46368" 
              alt="Sri Sri Radha Damodar 3"
            />
          </div>
        </Carousel>
      </div>

      <div className="title">
        <h2 style={{padding:"1rem"}}>Devotional Disciple of the Divine Couple- Sri Sri Radha Damodar</h2>
      </div>
    </section>
  );
}

export default ServantSection;
