import React from 'react';
import './Introduction.css';

function Introduction() {
  return (
    <section className="introduction">
      <h2>About HG Krishna Bhakta Das</h2>
      <p>
        HG Krishna Bhakta Das is a dedicated Brahmachari and the Temple President of ISKCON Solapur. 
        With his unwavering commitment to spiritual growth and community development, 
        he has been serving as a Zonal Supervisor for Maharashtra, guiding and inspiring 
        devotees across the region.
      </p>
    </section>
  );
}

export default Introduction;
