import React from "react";
import HeroSection from "./Components/HeroSection/HeroSection";
import Introduction from "./Components/HeroSection/Introduction/Introduction";
import Roles from "./Components/Roles/Roles";
import Footer from "./Components/Footer/Footer";
import "./App.css";
import ServantSection from "./Components/ServantSection/ServantSection";

function App() {
  return (
    <>
      <HeroSection/>
      <ServantSection/>
      <Introduction/>
      <Roles/>
      <Footer/>
    </>
  );
}

export default App;
