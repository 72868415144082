import React from 'react';
import './Roles.css';

function Roles() {
  return (
    <section className="roles">
      <h2>Leadership Roles</h2>
      <div className="role-card">
        <h3>Temple President - ISKCON Solapur</h3>
        <p>
          HG Krishna Bhakta Das oversees the management and spiritual activities of the ISKCON Solapur temple, 
          ensuring that the teachings of Srila Prabhupada are upheld in every aspect of temple life. 
          He is responsible for organizing festivals, daily worship, and community outreach.
        </p>
      </div>

      <div className="role-card">
        <h3>Zonal Supervisor - Maharashtra</h3>
        <p>
          As the Zonal Supervisor, he oversees the development of ISKCON centers across Maharashtra, 
          providing guidance and support to temple leaders. His supervision ensures a harmonious environment 
          for spiritual practice and growth throughout the region.
        </p>
      </div>
    </section>
  );
}

export default Roles;
