import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="header">
        <h2 style={{
          paddingTop:"1rem",
          paddingBottom:"1rem"
        }}>HG Krishna Bhakta Das</h2>

      <div className="image-container">
        <img 
          src="https://instagram.fbek1-1.fna.fbcdn.net/v/t39.30808-6/456413732_18457175359013001_5485233723809340389_n.jpg?stp=dst-jpg_e35&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyLmYzMDgwOC5kZWZhdWx0X2ltYWdlIn0&_nc_ht=instagram.fbek1-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=1YV8aZkEs9QQ7kNvgGD26bH&edm=APoiHPcAAAAA&ccb=7-5&ig_cache_key=MzQ0MDA0ODUwNTUxMTczMzgxNw%3D%3D.3-ccb7-5&oh=00_AYC966mD4k6SZ-fAx8gbU84m3Fnq4RA8QIpHKa0S1lvM0g&oe=66F61E70&_nc_sid=22de04"
          alt="HG Krishna Bhakta Das"
          className="profile-pic"
        />
      </div>
      <div className="profile-title-container">
        <div className="profile-title">
          <h2>Temple President,</h2>
          <h2><span>ISKCON Solapur</span></h2>
        </div>
        <div className="profile-subtitle">
          <h3>Zonal Supervisor, <span>Maharashtra</span></h3>
        </div>
      </div>
    </header>
  );
}

export default Header;
