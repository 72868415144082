import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p>Connect with HG Krishna Bhakta Das</p>
      <a href="mailto:iskcon.solapur@gmail.com">Email</a> | <a href="https://www.facebook.com/kbd.lok/">Facebook</a>
    </footer>
  );
}

export default Footer;
